@import "variables";
@import "mixins";

.tr-login-logo {
  display: inline-block;
  object-fit: contain;
  outline: none;
  margin-top: 60px;

  &:focus, &:active {
    outline: none;
  }

  img {
    max-height: 50px;
  }

  @include respond-to-custom-max($tr-desktop-mobile - 1) {
    display: block;
    text-align: center;
    margin-top: 10px;
  }
}

.tr-component-title {
  font-size: 1.125em;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: left;
  color: $primary-text-color;
  margin: 0 0 30px;
}

.tr-component-description {
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: $primary-text-color;
  margin: 0 0 20px;
}

.tr-note {
  border-radius: 10px;
  background-color: transparentize($carolina-blue, 0.8);
  padding: 10px 20px;
  font-weight: 500;
  color: #000;
  margin-bottom: 20px;
}

.tr-dashboard-actions {
  margin-top: 20px;
  padding: 10px 20px;
}

@include respond-to-custom-min($tr-desktop-mobile) {
  .tr-component-title {
    font-size: 24px;
  }

  .tr-component-description {
    font-size: 1em;
  }

  .tr-note {
    font-size: 18px;
  }
}

