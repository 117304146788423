.mdc-menu-surface {
  &.mat-mdc-autocomplete-panel.multiselect-autofill-panel {
    transform: translateY(10px);
  }

  &.mat-mdc-select-panel.country-code-list {
    min-width: 250px;

    lib-country-flag {
      display: inline-block;
      padding: 0 10px;
    }

    .phone-opt {
      height: 32px;
    }

    .mdc-list-item__primary-text {
      flex: 1;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .phone-code {
        width: 34px;
        display: inline-block;
      }
    }
  }
}
