@import "tr-variables";
@import "tr-mixins";

.mat-mdc-form-field {
  margin-top: $mat-form-field-label-top;

  .mat-mdc-form-field-flex {
    .mat-mdc-form-field-infix {
      display: flex;
      align-items: center;

      input {
        padding: 0;
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px white inset !important;
        }
      }
    }
  }

  span.mat-form-field-label-wrapper {
    top: -$mat-form-field-label-top;
    left: -14px;

    .mat-mdc-form-field-label {
      @include mat-label(14px);
    }
  }

  // removed offsets of error message
  .mat-mdc-form-field-subscript-wrapper {
    --mdc-typography-caption-font-size: 12px;
    --mdc-typography-caption-line-height: 14px;
    --mdc-typography-caption-font-weight: 500;
  }

  &.mat-form-field-appearance-outline {
    &:hover, &:focus, &.mat-focused {
      .numerical-arrows {
        opacity: 0.8;
      }

      .vertical-separator {
        margin-top: -1px;
        border-right-width: 2px;
        border-right-color: var(--tr-color-secondary, $tr-color-secondary),;
      }

      .mat-mdc-input-element {
        caret-color: var(--tr-color-secondary, #{$pacific-blue});
      }
    }

    &.mat-form-field-invalid:hover, &.mat-form-field-invalid {
      .vertical-separator {
        margin-top: -1px;
        border-right-width: 2px;
      }
    }
  }

  &.long-error {
    &.ng-untouched, &.ng-valid {
      margin-block-end: 25px;
    }

    .mat-mdc-form-field-bottom-align::before {
      content: none;
    }

    .mat-mdc-form-field-error-wrapper {
      position: inherit;
      margin-top: 10px;
      margin-bottom: 5px;
    }
  }

  &.no-errors .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  &.ng-pristine .mat-mdc-text-field-wrapper, .mat-mdc-text-field-wrapper {
    &.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline {
      &__leading, &__notch, &__trailing {
        border-color: $tr-gainsboro;
      }
    }
  }

  &.ng-touched .mat-mdc-text-field-wrapper {
    &.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline {
      &__leading, &__notch, &__trailing {
        border-color: $tr-color-danger;
      }
    }
  }

  &.warning {
    .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover,
    .mat-mdc-text-field-wrapper.mdc-text-field--invalid:not(.mdc-text-field--disabled) {
      .mdc-notched-outline {
        &__leading, &__notch, &__trailing {
          border-color: $tr-color-warning;
        }
      }
    }
  }

  &.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap {
    border-top-color: currentColor;
  }
}

.register_block{
  .mat-mdc-form-field {
 
  &.ng-touched .mat-mdc-text-field-wrapper {
    &.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline {
      border-left-color: transparent;
      &__notch, &__trailing {
        border-color: inherit;
      }
      &__leading{
        border-color: inherit;
        border-left: 1px solid;
      }
    }
  }
  
}
}