// Main Fonts
$font-family-public: 'Montserrat', 'Avenir-Medium', 'Avenir', Helvetica, sans-serif;
$font-family-admin: 'Avenir', Helvetica, monospace;

// Base Color Variables
$tr-white-smoke: #f4f5f9;
$tr-ash-grey: #aeb9c4;
$tr-payne-grey: #4e597b;
$tr-manatee: #999999;
$tr-platinum: #e0e1e2;
$tr-munsell: #eef1f3;
$tr-gainsboro: #dcdcdc;
$tr-pale-aqua: #c2c9e2;
$ghost-white: #f8f9fa;
$tr-lavender-mist: #e2e6f3;
$tr-medium-turquoise: #52cae0;
$tr-need-help-bg: #e6ebf5;
$tr-need-help-bg-hover: #cedaef;
$tr-confirm-button: #5F7D9B;
$cadet: #47606d;
$india-red: #e05a57;
$india-red-disabled: rgba(224, 90, 87, 0.5);
$fuzzy-wuzzy: #d86363;
$dark-tangerine: #f5a623;
$jungle-green: #22989c;
$pacific-blue: #23b2c1;
$teal-blue: #2f8f99;
$powder-blue: #b2e4ea;
$carolina-blue: #90b8d2;
$lavender-gray: #c6cbd4;
$cool-grey: #8f97b0;
$taupe: #333333;
$rifle-green: #3e3e3e;
$pale-cerulean: #94c9cf;
$lawn-green: #90dc0b;
$dark-gray: #aba7a7;

// Color Variables by application
$tr-color-primary: #52cae0;
$tr-color-primary-light: #a8e4ef;
$tr-color-primary-contrast: #3c9aab;
$tr-color-primary-text: #ffffff;
$tr-color-primary-text-disabled: #ffffff;

$tr-color-secondary: #f2f1f6;
$tr-color-secondary-light: #f2f1f6;
$tr-color-secondary-contrast: #eae8f1;
$tr-color-secondary-text: #3e3e3e;
$tr-color-secondary-text-disabled: #cfcfcf;

$tr-color-tertiary: #5167a3;
$tr-color-tertiary-light: #919FD3;
$tr-color-tertiary-contrast: #263663;
$tr-color-tertiary-text: #ffffff;
$tr-color-tertiary-text-disabled: #9DA4C3;

$tr-color-warning: #f5a623;
$tr-color-danger: #ff5e5e;

$tr-color-dark: #3f3f3f;
$tr-color-light: #FFFFFF;
$tr-color-placeholder: #999999;
$tr-color-control-icon: #b1b1b1;
$tr-color-control-icon-hover: #8d8a8a;
$tr-color-header-link: #616d91;
$tr-color-active-status: #8894c9;
$tr-color-plus-button-disabled: #b4dade;
$tr-background-active-status: #ebf1ff;
$tr-color-outline: #c2c2c2;

$tr-tertiary-gradient: linear-gradient(-180deg, rgba(71, 89, 145, 1) 0%, rgba(34, 45, 88, 1) 100%);
$tr-primary-gradient: linear-gradient(-180deg, rgba(119, 230, 249, 1) 0%, rgba(65, 183, 204, 1) 100%);
$tr-footer-gradient: linear-gradient(-90deg, rgba(40, 52, 116, 1) 0%, rgba(85, 98, 151, 1) 100%);

$dialog-title: #343434;
$danger-color: #bd0303;

// TODO we have to deprecate this variables to use css-variables for each of the apps
$grey-mid: rgba(0, 0, 0, 0.54);

$primary-color: $india-red;
$primary-color-disabled: $india-red-disabled;
$secondary-color: $jungle-green;
$primary-text-color: $rifle-green;
$paragraph-primary-text-color: $rifle-green;
$checkbox-label: $rifle-green;

$admin-primary-text-color: $taupe;
$admin-secondary-text-color: $tr-manatee;
$admin-primary-bg-color: #ffffff;
$admin-secondary-bg-color: $ghost-white;

$admin-primary-color: $pacific-blue;
$admin-primary-link-color: $pacific-blue;

$public-questions-list-bg: rgba($ghost-white, 0.6);
$public-questions-primary: #a6aebc;
$public-questions-accent: #566173;

// Dimensions
$tr-mobile: 480;
$tr-desktop-mobile: 576;
$tr-desktop-small: 768;
$tr-desktop-medium: 992;
$tr-desktop-large: 1200;
$tr-content-on-desktop-large-max-width: 1020px;
$tr-desktop-default: 1410;
$tr-dashboard-size: 1150;
$desktop-height: calc(100vh - 390px);

$tr-desktop-standard-padding: calc((100vw - 1410px)/2);
$tr-dashboard-padding: calc((100vw - 1150px)/2);
$tr-android-mobile-navigation: 55px;
$tr-android-tab-navigation: 76px;

$breakpoints: (
  'mini-mobile-1': $tr-mobile - 1 + 'px',
  'mini-mobile': $tr-mobile + 'px',
  'mini-mobile+1': $tr-mobile + 1 + 'px',
  'mobile-1': $tr-desktop-mobile - 1 + 'px',
  'mobile': $tr-desktop-mobile + 'px',
  'mobile+1': $tr-desktop-mobile + 1 + 'px',
  'small-1':  $tr-desktop-small - 1 + 'px',
  'small':  $tr-desktop-small + 'px',
  'small+1':  $tr-desktop-small + 1 + 'px',
  'medium-1': $tr-desktop-medium - 1 + 'px',
  'medium': $tr-desktop-medium + 'px',
  'medium+1': $tr-desktop-medium + 1 + 'px',
  'large-1':  $tr-desktop-large - 1 + 'px',
  'large':  $tr-desktop-large + 'px',
  'large+1':  $tr-desktop-large + 1 + 'px',
  'default-1': $tr-desktop-default - 1 + 'px',
  'default': $tr-desktop-default + 'px',
  'default+1': $tr-desktop-default + 1 + 'px',
) !default;

// Buttons
$btn-color: $pacific-blue;
$btn-min-width: 140px;
$button-height: 60px;
$survey-button-height: 60px;
$button-flat-hover: $teal-blue;
$btn-navigation-height: 40px;
$btn-procedure-desktop-height: 40px;
$btn-procedure-height: 25px;

$admin-standard-indent: 10px;
$mobile-standard-padding: 20px;
$desktop-standard-padding: 20px;
$mobile-max-width: 480px;
$tablet-min-width: 481px;
$tablet-max-width: 768px;
$ipad-max-width: 1024px;
$desktop-max-width: 1150px + 2 * $desktop-standard-padding;
$desktop-question-area-width: 900px;
$tablet-question-list-width: 241px;
$desktop-question-list-width: 382px;
$mat-form-field-label-top: 10px;
$survey-line-height: 26px;

// Calendar
$tr-calendar-width: 255px;
