@import "tr-variables";
@import "tr-mixins";

.mat-mdc-button.mat-unthemed:not(:disabled) {
  --mdc-text-button-label-text-color: #{$pacific-blue};
}

@mixin mat-primary-color-button-for-survey {
  &.mat-primary {
    background-color: var(--tr-color-primary, $tr-color-primary);
    color: var(--tr-color-primary-text, $tr-color-primary-text);

    &:hover {
      background-color: var(--tr-color-primary-contrast, $tr-color-primary-contrast);
    }

    &:disabled {
      background-color: var(--tr-color-primary-light, $tr-color-primary-light);
      color: var(--tr-color-primary-text-disabled, $tr-color-primary-text-disabled);
    }

    &.is-shadowed {
      box-shadow: 5px 10px 15px 0 rgba(82, 202, 224, 0.2);
    }
  }
}

@mixin mat-secondary-and-tertiary-color-button {
  &.mat-secondary {
    background-color: var(--tr-color-secondary, $tr-color-secondary);
    color: var(--tr-color-secondary-text, $tr-color-secondary-text);

    &:hover {
      background-color: var(--tr-color-secondary-contrast, $tr-color-secondary-contrast);
    }

    &:disabled {
      background-color: var(--tr-color-secondary-light, $tr-color-secondary-light);
      color: var(--tr-color-secondary-text-disabled, $tr-color-secondary-text-disabled);
    }
  }

  &.mat-tertiary {
    background-color: var(--tr-color-tertiary, $tr-color-tertiary);
    color: var(--tr-color-tertiary-text, $tr-color-tertiary-text);

    &:hover {
      background-color: var(--tr-color-tertiary-contrast, $tr-color-tertiary-contrast);
    }

    &:disabled {
      background-color: var(--tr-color-tertiary-light, $tr-color-tertiary-light);
      color: var(--tr-color-tertiary-text-disabled, $tr-color-tertiary-text-disabled);
    }
  }
}

@mixin mat-button-with-icon($button-size: 50px, $icon-size: 22px) {
  &.is-with-icon {
    color: var(--tr-color-tertiary, $tr-color-tertiary);
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;

    mat-icon.mat-icon {
      width: $icon-size;
      height: $icon-size;
      line-height: $icon-size;
      margin-left: unset;
    }

    &.is-rounded {
      min-width: unset;
      border-radius: 50%;
      padding: 0;
      width: $button-size;
      height: $button-size;

      mat-icon {
        margin-right: 0;
        margin-left: 0;
      }

      &.mat-mdc-button-persistent-ripple {
        min-width: unset;
      }
    }

    &.is-squared {
      padding: 0;
      width: 40px;
      min-width: 40px;
      height: 40px;

      mat-icon.mat-icon {
        margin-right: 0;
        width: 16px;
        height: 16px;
      }
    }

    @include respond-to-max('mobile-1') {
      min-width: unset;
    }
  }
}

@mixin material-button-width($width: 335px) {
  button.mat-mdc-unelevated-button, button.mat-mdc-outlined-button {
    width: $width;
  }
}

@mixin generic-flat-button() {
  border-radius: 6px;
  box-sizing: border-box;
  font-weight: bold;
  line-height: normal;

  @include respond-to-custom-max($tr-desktop-medium - 1) {
    width: 100%;
  }

  &.is-with-icon {
    font-weight: 600;

    &:disabled {
      .mat-button-wrapper {
        color: var(--tr-color-tertiary, $tr-color-tertiary);
      }
    }

    mat-icon {
      color: var(--tr-color-tertiary, $tr-color-tertiary);
      width: 22px;
      height: 22px;
    }
  }

  &.is-shadowed {
    background-color: var(--tr-color-primary, $tr-color-primary);
    box-shadow: 5px 10px 15px 0 rgba(82, 202, 224, 0.2);
  }

  .mat-button-wrapper {
    font-family: $font-family-public;
    font-weight: 500;
  }
}

.mat-button.survey-button[mat-button],
.mat-mdc-unelevated-button.survey-button[mat-flat-button] {
  height: $survey-button-height;
  min-width: 170px;

  @include mat-primary-color-button-for-survey();
}

.mdc-button__label {
  white-space: nowrap;
}
