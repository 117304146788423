@import "tr-variables";

@mixin transformAnyBrowser($degrees) {
  -webkit-transform: rotate($degrees);
  -moz-transform: rotate($degrees);
  -o-transform: rotate($degrees);
  -ms-transform: rotate($degrees);
  transform: rotate($degrees);
}

@mixin animationDelayAnyBrowser($delay) {
  -webkit-animation-delay: $delay;
  animation-delay: $delay;
}

@mixin closeIcon {
  position: absolute;
  cursor: pointer
}

@mixin closeIconOnRight($indent, $topIndent: $indent) {
  @include closeIcon;
  right: $indent;
  top: $topIndent;
}

