@import "tr-variables";
@import "tr-base";
@import "tr-mixins";
@import "tr-buttons";
@import "tr-dialogs";
@import "functions";
@import "tr-mat-form-field";
@import "tr-mat-select";
@import "tr-mat-radio-checkbox";

lib-survey {
  @include respond-to-min('medium') {
    display: flex;
    justify-content: center;
    align-items: stretch;
    width: 100%;
  }
}

.lib-survey-wrapper {
  --mdc-typography-caption-font-size: 12px;
  --mdc-typography-caption-line-height: 14px;
  --mdc-typography-caption-font-weight: 500;
  --mdc-theme-text-primary-on-background: #{$taupe};
  --mdc-checkbox-state-layer-size: 20px;
  --mdc-radio-state-layer-size: 20px;

  .mat-mdc-form-field-bottom-align::before {
    height: unset;
  }

  h3 {
    color: var(--tr-color-text, #{$taupe});
  }

  .mdc-form-field {
    gap: 10px;
  }

  .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
    margin-top: 4px;
    padding: unset;
  }

  &.loading {
    display: flex;
    justify-content: center;
    flex: 1;
  }

  .loading-page {
    text-align: center;
    justify-content: center;
  }

  .mat-mdc-unelevated-button[mat-flat-button] {
    border-radius: 5px;
    line-height: 60px;
    font-weight: bold;

    @include respond-to-min('mobile') {
      &.is-with-icon {
        min-width: 215px;
        gap: 7px;
      }

      &.is-squared {
        gap: unset;
      }
    }

    &.mat-mdc-button-base {
      @include mat-primary-color-button-for-survey();
      @include mat-secondary-and-tertiary-color-button();
      @include mat-button-with-icon();
    }
  }

  .toolbar.mat-toolbar-multiple-rows {
    width: unset;
  }

  lib-submitted-consent {
    margin-bottom: 40px;
  }

  @include respond-to-max('mobile-1') {
    mat-form-field.mat-mdc-form-field {
      display: block;
    }

    .mdc-text-field {
      border-radius: unset;
    }
  }

  lib-tag {
    --tag-color: #{$tr-medium-turquoise};
  }

  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: var(--tr-color-placeholder, $tr-color-placeholder);
    font-size: 16px;
    font-weight: 500;
  }

  .option-label {
    font-weight: 500;
    font-size: 12px;
  }
}

lib-question {
  input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;

    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
  }

  mat-form-field.mat-mdc-form-field {
    --mdc-typography-body1-line-height: 25px;

    margin-top: 0;

    &.mat-form-field-appearance-outline .mat-form-field-outline {
      color: $tr-color-outline;
    }

    &.a1c-date {
      margin-top: 16px;
    }

    .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
      padding-bottom: 10px;
      padding-top: 10px;
    }
  }

  .mat-radio-label-content, .mat-checkbox .mat-checkbox-layout {
    line-height: $survey-line-height;
    white-space: break-spaces;
  }

  lib-radio-with-text-widget, lib-checkbox-with-text-widget {
    width: 100%;

    mat-form-field.mat-mdc-form-field {
      display: block;
    }

    .text-input {
      margin-left: 30px;
      max-width: 430px;
    }

    .mat-mdc-radio-group, .mat-mdc-checkbox {
      display: block;
    }

    &.flex {
      align-items: center;
      flex-wrap: wrap;

      .mat-radio-label, .mat-checkbox-label {
        margin-bottom: unset;
      }

      & > mat-form-field.mat-mdc-form-field {
        margin-left: unset;
      }
    }

    mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper {
      padding-bottom: unset;
    }
  }
}

.field-error {
  font-size: 75%;
  color: var(--tr-color-danger, $tr-color-danger);
  margin-bottom: 5px;
}

.tooltip-field {
  grid-column: span 2;
}

.phone-input-wrapper > mat-form-field.mat-mdc-form-field.mat-form-field-appearance-outline {
  &.mat-form-field-invalid, & .mat-mdc-text-field-wrapper:hover {
    lib-phone-input .mat-mdc-form-field.phone-number .mdc-notched-outline {
      &__leading, &__notch, &__trailing {
        border-color: transparent;
      }
    }
  }
}

lib-form-control-error {
  font-size: var(--mdc-typography-caption-font-size, 12px);
  display: inline-block;
}

lib-phone-input + mat-error {
  margin-top: 4px;
}

@include respond-to-max('medium-1') {
  .phone-input-wrapper {
    width: 100%;
  }
}

input[aria-label="ZIP Code"] {
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

.icon-container {
  cursor: pointer;

  mat-icon {
    padding: 0;
    color: $tr-ash-grey;
    height: 28px;
  }
}

.consent-status-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
  align-items: flex-start;

  @include respond-to-max('large-1') {
    display: block;
    text-align: center;

    button {
      width: 100%;
    }
  }

  > div {
    flex: 1 1 50%;
  }

  img {
    max-width: 411px;
    width: 100%;
    margin-top: 15px;
  }

  .text {
    @include respond-to-max('large-1') {
      text-align: left;
    }

    @include generic-inner-html-text();

    button.mat-mdc-unelevated-button {
      @include generic-flat-button();
    }
  }
}

lib-date-widget, lib-date-short-widget {
  @include respond-to-min('mobile') {
    .mat-mdc-text-field-wrapper {
      max-width: $tr-calendar-width;
    }
  }
}

lib-icon-time {
  margin-right: 10px;
  margin-top: 15px;
}

lib-survey-consent, lib-survey-intro {
  display: block;

  @include respond-to-max('medium-1') {
    margin-block-start: 60px;
    max-width: 950px;
    margin-left: auto;
    margin-right: auto;
    padding-block-end: 40px;
  }
}

lib-survey-intro .mat-mdc-unelevated-button {
  text-transform: capitalize;

  @include respond-to-custom-max($tr-desktop-mobile - 1) {
    width: 100%;
  }
}

.intro-title {
  align-items: center;

  h3 {
    margin-right: 20px;
  }
}

.intro-title {
  @at-root {
    hr#{&} {
      color: $tr-white-smoke;
      border: 1px solid $tr-white-smoke;
    }

    h4#{&} {
      font-size: 16px;
      color: $tr-color-placeholder;
    }
  }

  .mat-icon {
    @include mat-icon-size(68px);
    margin-right: 20px;
    flex-shrink: 0;
  }
}

.procedure-status-title {
  font-weight: 700;
  font-size: 24px;
  font-family: $font-family-public !important;
}

.procedure-closed-status, lib-study-is-closed-banner, .procedure-research-status {
  width: 100%;
  max-width: 950px;
  margin: 0 auto;
}

lib-procedure-status, lib-closed-study, lib-unavailable-consent {
  .wrapper {
    padding: 0 40px;

    @include set-large-desktop-to-center(1200px);

    @include respond-to-custom-max($tr-desktop-medium - 1) {
      margin-top: 30px;
      margin-bottom: 60px;
    }

    @include respond-to-custom-max($tr-desktop-mobile - 1) {
      padding: 0 20px;
    }

    @include respond-to-custom-min($tr-desktop-medium) {
      margin-top: 80px;
    }
  }

  .shortcuts {
    justify-content: space-between;
    align-items: center;
    margin-bottom: 60px;

    @include respond-to-custom-max($tr-desktop-medium - 1) {
      margin-bottom: 30px;
    }
  }

  @include generic-inner-html-text();
}

.link-actions {
  gap: 20px;
  margin: 50px 0 40px;

  .mat-mdc-unelevated-button.survey-button[mat-flat-button] {
    min-width: 210px !important;
  }

  @include respond-to-max('small-1') {
    flex-direction: column;
    align-items: stretch;

    .mat-mdc-button {
      width: 100%;
    }
  }
}

.actions-content button.survey-button[color="primary"] {
  min-width: 250px;
  margin-bottom: 40px;
}

.dark-blue {
  background-color: rgba(81, 103, 163, 1);
}

.light-blue {
  background-color: $tr-medium-turquoise;
}

.show-more {
  color: $tr-medium-turquoise;
  cursor: pointer;
  font-size: 1em;
  font-weight: 500;
  margin-block-start: 15px;
  margin-block-end: 20px;
}

.one-col-form {
  display: grid;
}

.col-form {
  display: grid;
  grid-column-gap: 25px;
  grid-row-gap: 10px;
  align-items: baseline;
}

.two-col-form {
  @extend .col-form;
  grid-template-columns: 1fr 1fr;

  @include respond-to-max('medium-1') {
    grid-column-gap: 10px;
  }

  @include respond-to-max('mobile-1') {
    grid-template-columns: 1fr;
  }
}

.three-col-form {
  @extend .col-form;
  grid-template-columns: 1fr 1fr 1fr;

  @include respond-to-max('medium-1') {
    grid-template-columns: auto;
    grid-column-gap: 10px;
  }

  @include respond-to-max('mobile-1') {
    grid-template-columns: 1fr;
  }
}

.numerical-arrows {
  opacity: 0;
  position: absolute;
  right: -15px;
  bottom: 2px;
  background: #FAFAFA;
  border-radius: 5px;
  color: currentColor;

  .material-icons {
    font-size: 22px;
    border: 1px white solid;

    &.up, &.down {
      cursor: pointer;
    }

    &.up {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    &.down {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}
