@import "tr-variables";
@import "tr-mixins";

.lib-survey-wrapper {
  @include respond-to-max('medium-1') {
    lib-contact-info .mat-mdc-unelevated-button.mat-mdc-button-base[mat-flat-button].is-with-icon {
      height: 40px;
      line-height: 40px;
      min-width: unset;
    }
  }
}
