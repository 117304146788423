@import "tr-variables";

@mixin ellipsis() {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@mixin layoutGap($distance) {
  > *:not(:last-child) {
    margin-right: $distance;
  }
}

@mixin mat-icon-size($size: 24px) {
  font-size: $size;
  height: $size;
  width: $size;
  line-height: $size;
}

@mixin except-iOS-devices {
  @supports not (-webkit-touch-callout: none) {
    @content;
  }
}

@mixin for-iOS-gadgets-only {
  @supports (-webkit-touch-callout: none) {
    @content;
  }
}

@mixin for-Safari {
  @supports (-webkit-overflow-scrolling: touch) {
    @content
  }
}

@mixin overflow-y-touch {
  overflow-y: auto;
  @include for-Safari {
    // works both auto and scroll, overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

@mixin iPhone-landscape {
  @media only screen and (max-device-width: $mobile-max-width) and (orientation:landscape) {
    @content;
  }
}

@mixin iPad {
  @media only screen and (min-width: $tablet-max-width) and (max-width: $ipad-max-width)
  { @content; }
}

@mixin iPad-portrait {
  @media screen and (min-device-width: $tablet-min-width) and (max-device-width: $ipad-max-width) and (orientation:portrait) {
    @content;
  }
}

@mixin iPad-landscape {
  @media screen and (min-device-width: $tablet-min-width) and (max-device-width: $ipad-max-width) and (orientation:landscape) {
    @content;
  }
}

@mixin before-height($resolution) {
  @media screen and (max-height: $resolution - 1) {
    @content;
  }
}

@mixin after-resolution($resolution) {
  @media screen and (min-width: $resolution) {
    @content;
  }
}

@mixin view-open-close-eye() {
  mat-icon[svgIcon="view-open"],
  mat-icon[svgIcon="view-close"] {
    cursor: pointer;
    width: 22px;
    height: 22px;
    margin-right: 10px;

    &:hover use#Icon {
      fill: $carolina-blue;
    }
  }

  .mat-form-field-appearance-outline .mat-form-field-suffix {
    top: 7px;
  }
}

@mixin ie11 {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

@mixin generic-inner-html-text() {
  h5 {
    font-size: 20px;
    font-weight: 600;
    margin-top: 0;
  }

  p {
    line-height: 32px;
    font-size: 16px;
    font-weight: 500;
  }
}

@mixin generic-ul-style() {
  ul {
    list-style: none;
    padding: 0;

    li {
      padding: 20px 40px;
      background-image: url("/assets/bullet_point.svg");
      background-repeat: no-repeat;
      background-position-y: center;
      font-family: $font-family-public;
      font-size: 16px;
      font-weight: 500;
    }
  }
}

@mixin mat-label($font-size: 12px, $label-color: $tr-ash-grey) {
  color: $label-color;
  font-size: $font-size;
  font-weight: 600;
  text-transform: uppercase;
}

/// Mixins to manage responsive breakpoints
/// @param {String} $name - Breakpoint name
/// @require $breakpoints
@function get-breakpoint($name, $breakpoints) {
  $bp: map-get($breakpoints, $name);
  @return if($bp != 0, $bp, null);
}

@mixin respond-to-min($name, $breakpoints: $breakpoints) {
  $min: get-breakpoint($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin respond-to-max($name, $breakpoints: $breakpoints) {
  $max: get-breakpoint($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin respond-to-between($lower, $upper, $breakpoints: $breakpoints) {
  $min: get-breakpoint($lower, $breakpoints);
  $max: get-breakpoint($upper, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include respond-to-min($lower, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include respond-to-max($upper, $breakpoints) {
      @content;
    }
  }
}

@mixin respond-to-custom-min($screen) {
  @media (min-width: $screen+'px') {
    @content;
  }
}

@mixin respond-to-custom-max($screen) {
  @media (max-width: $screen+'px') {
    @content;
  }
}

@mixin respond-to-custom-between($min, $max) {
  @media (min-width: $min+'px') and (max-width: $max+'px') {
    @content;
  }
}

@mixin respond-to-device-max-height($max) {
  @media only screen and (max-device-height: $max+'px') and (orientation:landscape) {
    @content;
  }
}

@mixin respond-to-device-min-height($min) {
  @media only screen and (min-device-height: $min+'px') and (orientation:portrait) {
    @content;
  }
}

@mixin respond-to-custom-max-height($max) {
  @media (max-height: $max+'px') {
    @content;
  }
}

@mixin procedure-status-icon {
  align-items: center;
  padding: 8px;
  border-radius: 10px;
  margin-right: 10px;
  font-weight: bold;
  font-size: 14px;
  margin-top: 15px;

  mat-icon.mat-icon {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
}

@mixin paragraph-on-mobile {
  p {
    margin-block-start: 0;
    padding: 0;
    line-height: 32px;
    margin-right: 0;
    width: auto;
    display: inline-block;
  }
}

@mixin mobile-dialog {
  @include respond-to-max('mobile-1') {
    padding-top: 45px;

    .close-icon .material-icons {
      margin-top: 5px;
    }

    @include paragraph-on-mobile;
  }
}

@mixin set-large-desktop-to-center($maxWidth: $tr-content-on-desktop-large-max-width) {
  @include respond-to-custom-min($tr-desktop-large + 1) {
    margin-left: auto;
    margin-right: auto;
    max-width: $maxWidth;
  }
}

@mixin sliceModalsWithScrollingAndWithoutImage() {
  height: unset;
  overflow-y: hidden;

  img {
    display: none;
  }

  .inner-text {
    overflow-y: auto;
    flex-shrink: 2;
  }
}

@mixin setting-card {
  color: var(--tr-color-secondary-text, $tr-color-secondary-text);
  max-width: 850px;
  padding: 20px 0;
  border-radius: 15px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  flex-direction: column;

  li, p {
    font-size: 14px;
  }

  .grey-label {
    color: var(--tr-color-placeholder, $tr-color-placeholder);
    font-weight: 600;
  }

  .header, .footer {
    padding: 0 20px;
  }

  lib-notification-toggle {
    display: block;
  }

  lib-notification-toggle, .header {
    padding-left: 20px;
    padding-right: 20px;

    @include respond-to-max('mobile-1') {
      padding: 10px 25px 10px 25px;
    }
  }

  .header {
    &.underline {
      padding-bottom: 20px;
      border-bottom: 2px solid #f2f1f6;
    }

    @include respond-to-max('mobile-1') {
      flex-wrap: wrap;

      h5, .grey-label {
        display: block;
        margin-block-end: 15px;
      }

      button.mat-mdc-unelevated-button[mat-flat-button] {
        min-width: unset;
      }
    }
  }

  .footer {
    p {
      line-height: 22px;
    }

    ul {
      list-style-type: none;
      padding-inline-start: 0;

      li {
        min-height: 70px;

        mat-icon.mat-icon {
          margin-left: 20px;
          margin-right: 15px;
          height: auto;
        }

        &:nth-child(even) {
          background-color: #fafafa;
        }
      }
    }
  }

  mat-icon.mat-icon {
    width: 17px;
    height: 11px;

    &[svgIcon="lock-locked"] {
      width: 46px;
      height: 46px;
      flex-shrink: 0;
      margin-right: 16px;
    }
  }
}
