@import "tr-variables";

.lib-survey-wrapper {
  .mat-mdc-checkbox.mat-primary {
    --mdc-checkbox-unselected-icon-color: #{$tr-platinum};
    --mdc-checkbox-selected-icon-color: #{$tr-color-primary};
    --mdc-checkbox-selected-checkmark-color: #{$taupe};
  }

  .mat-mdc-radio-button.mat-accent {
    --mdc-radio-selected-focus-icon-color: #{$tr-color-primary};;
    --mdc-radio-selected-hover-icon-color: #{$tr-color-primary};;
    --mdc-radio-selected-icon-color: #{$tr-color-primary};;
    --mdc-radio-selected-pressed-icon-color: #{$tr-color-primary};;
    --mat-mdc-radio-checked-ripple-color: #{$tr-color-primary};;
  }

  mat-radio-button.mat-mdc-radio-button {
    .mat-radio-label-content {
      padding-left: unset;
    }

    .mat-radio-container {
      margin-right: 10px;
    }
  }

  mat-checkbox.mat-checkbox {
    .mat-checkbox-inner-container {
      vertical-align: top;
      margin-top: 5px;
    }

    .mat-checkbox-layout {
      white-space: normal;
    }

    .mat-checkbox-inner-container {
      margin-right: 14px;
    }
  }

  lib-likert-scale-widget .mat-mdc-radio-button.mat-accent {
    --mdc-radio-unselected-icon-color: #{$dark-gray};
  }
}
