@import "functions";
@import "tr-mixins";
@import "tr-variables";

.cdk-overlay-pane {
  --mdc-dialog-supporting-text-font: #{$font-family-public};

  .mat-mdc-dialog-actions {
    justify-content: center;
  }

  &.question-list {
    overflow-y: auto;

    .mdc-dialog .mdc-dialog__content {
      padding: 0;
    }
  }

  @include respond-to-max('mobile-1') {
    mat-dialog-container > * {
      width: unset;
    }
  }

  &.tr-modal-default {
    @include respond-to-max('mobile-1') {
      // TODO It should be fixed while implement TR-2897
      max-width: 100% !important;
      width: 100%;
      height: 100vh;
      height: 100svh;

      &.tr-withdrawal-modal mat-dialog-container, &.tr-confirmation-modal mat-dialog-container {
        padding: 20px;
      }

      .mdc-dialog .mdc-dialog__surface {
        display: flex;
        justify-content: center;
        padding: 20px;
      }
    }

    @include respond-to-min('small') {
      .mdc-dialog .mdc-dialog__surface {
        padding: 40px;
      }
    }

    h3 {
      font-weight: 600;
      font-size: 24px;
      margin-block-start: 10px;
      margin-top: 0;
      text-align: center;
    }

    .inner-text, p {
      font-weight: 500;
      font-size: 16px;
      line-height: 25px;
      padding: 0 15px;
      letter-spacing: normal;
      text-align: center;
      margin-bottom: 0;
    }

    .inner-text, .inner-text > p {
      padding: 0 15px;
    }

    .mat-mdc-dialog-container {
      overflow: visible;
      overflow-y: auto;
      text-align: center;
      border-radius: 20px;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);

      @include respond-to-max('mobile-1') {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0;
      }

      .dialog-button.mat-mdc-unelevated-button[mat-flat-button], .dialog-button.mat-mdc-outlined-button[mat-stroked-button] {
        margin-top: 20px;

        @include respond-to-max('mobile-1') {
          margin-left: unset;
        }
      }

      .close-icon {
        @include closeIcon;
        top: 0;
        right: 0;
        height: 20px;
        width: 20px;
      }

      .mdc-dialog__container {
        color: $primary-text-color;
        display: block;
        position: relative;

        .mat-dialog-title {
          font-weight: 400;
          color: $dialog-title;
          display: block;
          text-align: center;
        }

        .actions, .register-actions {
          justify-content: center;

          &.to-right {
            justify-content: flex-end;
          }

          button {
            &.cancel-button {
              font-weight: 400;
              color: $grey-mid;
              border: solid 1px $grey-mid;
              background-color: #ffffff;
            }

            &.confirm-button {
              font-weight: 400;
              background-color: $tr-confirm-button;
              border-radius: 2px;
              border: solid 1px $tr-confirm-button;
              color: white;
            }

            &[disabled] {
              background-color: $tr-platinum;
              border-color: $tr-platinum;
              cursor: not-allowed;
            }

            + button {
              margin-left: 20px;
            }
          }
        }

        @include respond-to-min('mobile') {
          .mat-dialog-title {
            font-size: 18px;
            line-height: 20px;
            margin: 0 0 20px;
          }

          .actions, .register-actions {
            margin: {
              top: 30px;
              bottom: 0;
            };

            button {
              font-size: 13px;
              width: 125px;
              height: 40px;
            }
          }
        }
      }

      @include respond-to-custom-max-height($tr-mobile) {
        @include sliceModalsWithScrollingAndWithoutImage();
      }

      @include respond-to-device-max-height($tr-mobile - 1) {
        @include sliceModalsWithScrollingAndWithoutImage();
      }
    }
  }
}

.tr-modal-page-preview {
  height: 100%;
  width: 100%;

  header {
    flex: 1;
    height: 80px;
    max-height: 80px;
    border-bottom: $ghost-white solid 1px;
    box-shadow: $ghost-white 0 1px 6px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 20px;
  }

  .page-preview-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1135px;
    margin: 60px auto 0 auto;

    .container-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 60px;
    }
  }
}
