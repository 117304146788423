@import "variables";
@import "tr-variables";
@import "tr-mixins";

// Modal dialogs
.mat-mdc-dialog-container {
  .mdc-dialog__title {
    --mdc-dialog-subhead-font: #{$font-family-public};
    --mdc-dialog-subhead-color: #{$tr-color-secondary-text};
  }

  .mat-mdc-dialog-content {
    h3 {
      text-align: center;
      font-weight: 600;
      line-height: 32px;
    }

    .message {
      margin-top: 0;
      margin-bottom: 10px;
    }
  }
}

.cdk-overlay-pane {
  &.public-modal {
    .mat-mdc-dialog-surface {
      border-radius: 20px;
      padding: 16px;

      @include respond-to-max('mobile-1') {
        border-radius: 0;
        padding: 0;
      }

      @include iPhone-landscape {
        border-radius: 0;
        padding: 0;
      }

      @include respond-to-device-max-height($tr-mobile - 1) {
        border-radius: 0;
        padding: 0;
      }
    }

    &.border-20 {
      .mat-mdc-dialog-surface {
        border-radius: 20px !important;
        padding: 20px 0;
      }
    }

    .mat-mdc-dialog-container {
      .mdc-dialog__container {
        max-height: calc(95vh - 40px); // Together with padding: 20px; (including 16px) in .mat-mdc-dialog-surface
        max-height: calc(95svh - 40px);
      }

      @include respond-to-custom-max-height($tr-mobile) {
        @include sliceModalsWithScrollingAndWithoutImage();
      }

      @include respond-to-device-max-height($tr-mobile - 1) {
        @include sliceModalsWithScrollingAndWithoutImage();
      }
    }

    @include respond-to-max('mobile-1') {
      &.withdrawal-modal, &.sms-consent-dialog {
        overflow-y: auto;

        .mat-mdc-dialog-container .mdc-dialog__container {
          max-height: unset;
        }

        @include for-iOS-gadgets-only() {
          .mat-mdc-dialog-actions {
            padding-bottom: 40px;
          }
        }
      }
    }

    .mat-mdc-dialog-title[mat-dialog-title] {
      text-align: center;
      position: relative;
    }

    .mat-mdc-dialog-actions[mat-dialog-actions] {
      gap: 20px;
      margin-bottom: 16px;

      .mat-mdc-button-base + .mat-mdc-button-base {
        margin-left: unset;
      }
    }

    &.confirmation-dialog {
      width: 600px;

      @include respond-to-max('mobile-1') {
        width: 100%;
      }
    }

    &.withdrawal-modal {
      .mdc-dialog__content {
        overflow: unset;
      }

      .mat-mdc-dialog-title[mat-dialog-title].mdc-dialog__title::before {
        height: unset;
      }

      .mat-mdc-dialog-content {
        max-height: unset;
      }
    }

    &.sms-consent-dialog {
      .mat-mdc-dialog-title[mat-dialog-title] {
        margin-top: 16px;
      }
    }

    @include respond-to-min('mobile') {
      .mat-dialog-actions {
        flex-wrap: nowrap;
      }
    }

    @include respond-to-max('mobile-1') {
      .mdc-dialog__container {
        max-height: unset;
      }
    }
  }
}

// SnackBar
.mat-mdc-snack-bar-container {
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);

  .mat-mdc-snack-bar-label {
    font-size: 14px;
    font-weight: bold;
    color: var(--tr-color-light, $tr-color-light);
    line-height: 20px;
    text-align: center;
  }

  .mat-mdc-button {
    min-width: 0;
    --mat-mdc-snack-bar-button-color: #{$tr-color-light};
  }

  &.success-snackbar {
    --mdc-snackbar-container-color: #{$tr-color-primary};
  }

  &.failed-snackbar {
    --mdc-snackbar-container-color: #{$tr-color-danger};
  }
}
