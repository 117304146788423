@use "sass:math";
@import "variables";
@import "functions";
@import "tr-mixins";
@import "mixins";
@import "./common";
@import "root";
@import "base";

.no-scroll {
  overflow: hidden;
}

#not-supported-browser {
  display: none;
}

.border-radius-30 {
  border-radius: 30px;
}

.text-normal {
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: var(--tr-color-dark, $tr-color-dark);
}

.mobile-only {
  @include respond-to-custom-min($tr-desktop-mobile) {
    display: none;
  }
}

.tablet-only {
  @include respond-to-custom-max($tr-desktop-mobile - 1) {
    display: none;
  }

  @include respond-to-custom-min($tr-desktop-medium) {
    display: none;
  }
}

.monitor-only {
  @include respond-to-custom-max($tr-desktop-mobile - 1) {
    display: none;
  }

  @include respond-to-custom-between($tr-desktop-mobile, $tr-desktop-medium - 1) {
    display: none;
  }
}

.desktop-only {
  @include respond-to-custom-max($tr-desktop-mobile - 1) {
    display: none;
  }
}

.close-icon {
  @include closeIcon;
  top: 0;
  right: 0;

  i {
    font-weight: bold;
    color: $tr-ash-grey;
  }
}


@include respond-to-custom-min($tr-desktop-mobile) {
  .text-normal {
    font-size: $font-size;
  }
}

.recaptcha-wrapper {
  position: relative;

  .mat-mdc-form-field-error {
    position: absolute;
    left: 0;
    bottom: -40px;
  }

  @include respond-to-custom-min($tr-desktop-mobile) {
    re-captcha {
      justify-content: center;
    }
  }
}

.link {
  color: $primary-color;
  text-decoration: none;
  font-size: 16px;
}

button.dialog-btn,
button.dialog-button {
  @include respond-to-custom-max($tr-desktop-mobile - 1) {
    width: 100%;
  }
}

.list-item,
.text-item {
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  padding-right: 17%;

  @include respond-to-custom-max($tr-desktop-mobile - 1) {
    padding-right: 0;
    padding-left: 0;
  }
}

.text-item {
  padding-left: 30px;

  @include respond-to-custom-max($tr-desktop-mobile - 1) {
    padding-left: 0;
  }
}

.text-list {
  padding-left: 60px;
  margin-bottom: 0;

  @include respond-to-custom-max($tr-desktop-mobile - 1) {
    padding-left: 30px;
  }

  .text-item {
    padding-left: 10px;
  }
}

.mat-select[panelclass="select-filter"] .mat-select-value-text,
.mat-select[panelclass="select-filter"] .mat-select-placeholder,
.select-filter .mat-option-text {
  font-size: 14px;
  font-weight: 600;
  color: #5b5b5b;
}

.setting-card {
  @include setting-card();
}

app-missed-on {
  @include procedure-status-icon();
}

.mdc-tooltip.mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: white;
  --mdc-plain-tooltip-supporting-text-color: #{$rifle-green};
  --mdc-plain-tooltip-supporting-text-size: 14px;
  --mdc-plain-tooltip-supporting-text-weight: 500;
  border-radius: 10px;
  box-shadow: 0 4px 20px 0 #0000000d;

  .mdc-tooltip__surface {
    line-height: 24px;
    padding: 15px 25px 15px 15px;
    max-width: 290px;
  }
}

lib-info-tooltip {
  .tooltip-message {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05) !important;
    border-radius: 10px !important;
    color: var(--tr-color-secondary-text, $rifle-green) !important;
    font-size: 14px !important;
    line-height: 24px !important;

    @include respond-to-min('medium') {
      padding: 25px !important;
      width: $tooltip-width;
    }
  }

  @include respond-to-min('medium') {
    .tooltip-message.site-center {
      left: math.div(-$tooltip-width, 2) !important;
      top: 100%;
    }
  }
}

app-user-profile {
  h4 {
    margin-top: 0;
    margin-bottom: 15px;
  }
}


/************** custom css *******************/


@media (max-width:900px) {
 app-questionnaire-history .flex.study-status button.survey-button {
   padding: 0;
   font-size: 0;
   text-align: center;
   width: 48px !important;
   height: 48px !important;
   border-radius: 50px !important;
   min-width: 48px !important;
 }
    app-questionnaire-history .flex.study-status button.survey-button mat-icon {
      margin: 0;
    }

}



@media (max-width:767px) {
  section.open-studies-and-research-opportunities h3 {
    padding-top: 24px;
}
  header a.is-with-icon.need-help {
    border-radius: 5px !important;
    background: #E6EBF5 !important;
  }

  section.complete-section {
    padding: 33px 15px !important;
    text-align: left !important;
    position: relative;
    overflow: hidden;
    margin-bottom: 0;
  }

  section.complete-section h2 {
    margin: 0;
    line-height: 34px !important;
    margin-bottom: 0 !important;
  }

  section.complete-section .title-part {
    width: 66%;
  }

  section.complete-section p {
    font-size: 14px;
    margin: 0;
    margin-top: 10px;
    max-width: 217px;
  }

  section.complete-section .image {
    width: 43%;
    position: absolute !important;
    right: -10px;
    bottom: 34px;
  }

  section.open-studies-and-research-opportunities h3 {
    margin-top: 0 !important;
    margin-bottom: 24px !important;
  }

  section.open-studies-and-research-opportunities {
    margin-top: 0 !important;
  }

  app-study-title-frame {
    margin: 0 !important;
    gap: 15px !important;
    margin-bottom: 20px !important;
  }

  section.open-studies-and-research-opportunities .left-part {
    gap: 8px;
  }

  section.open-studies-and-research-opportunities .left-part mat-icon {
    margin: 0;
  }

  .study-title-content p {
    font-size: 14px;
    line-height: 20px;
    margin-top: 5px;
  }

  app-study-title-frame mat-select {
    flex-basis: 89px !important;
  }

  app-study-status app-study-status-frame .flex.study-status-icon-and-title,
  app-questionnaire-history .study-status-icon-and-title {
    display: flex !important;
    gap: 15px !important;
    align-items: flex-start !important;
  }

  app-study-status app-study-status-frame .flex.study-status-icon-and-title span.flex.title-info,
  app-questionnaire-history .study-status-icon-and-title span.flex.title-info {
    margin-top: 0;
    font-weight: 600;
  }

  app-study-status app-study-status-frame .flex.study-status-icon-and-title span.flex.title-info lib-icon-time,
  app-questionnaire-history .study-status-icon-and-title span.flex.title-info lib-icon-time {
    margin-top: 8px;
    height: 30px;
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
  }

  app-study-status app-study-status-frame .flex.study-status,
  app-questionnaire-history .flex.study-status {
    margin: 0 !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between;
  }

  app-questionnaire-history .flex.study-status {
    justify-content: flex-end;
  }

  app-study-status app-study-status-frame .flex.study-status button.study-status-button,
  app-questionnaire-history .flex.study-status button.study-status-button {
    margin: 0 !important;
    width: auto;
    height: 34px;
    min-width: 140px;
  }

  app-study-status .study-status-progress,
  app-questionnaire-history .study-status-progress {
    margin-top: 0 !important;
  }

  app-study-status app-study-status-frame .flex.study-status-icon-and-title span.flex.title-info lib-icon-time mat-icon {
    margin: 0 !important;
  }

  lib-icon-time .time-content {
    margin: 0;
  }

  lib-icon-time .time-mat-icon {
    display: flex;
  }

  lib-icon-time.customTag.adjust-spacing {
    padding: 5px 12px !important;
  }

  app-study-title-frame[label="researches"] {
    margin-top: 30px !important;
  }

  section.closed-studies p b {
    color: #3e3e3e;
  }

  app-questionnaire-history .study-status-icon-and-title span.flex.title-info lib-icon-time {
    padding: 0;
  }

  .achievement-item {
    border-radius: 30px !important;
    padding: 20px !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      h5{
        margin: 0 !important;
      }
  }
    app-achievements {
      padding-bottom: 25px !important;
    }
        footer.footer.dashboard .flex {
          flex-direction: column;
        }

  .achievement-item-actions {
    height: auto !important;
  }

  .achievement-item p {
    margin-top: 10px;
  }

    app-dashboard-no-studies-yet {
      padding: 12px !important;
      border-radius: 15px;
      flex-direction: row-reverse !important;
      gap: 8px;
  
      h4 {
        font-size: 16px !important;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
      }
  
      p {
        margin: 5px 0 0;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
  
      img {
        width: 80px !important;
        padding: 0 !important;
      }
    }

}