@import "tr-variables";

$font-size: 16px;
$line-height: 24px;
$block-start-end: 20px;
$font-weight: 500;
// TODO Figure out where 'Proxima Nova' was used
// $font-family: 'Montserrat', 'Proxima Nova', Helvetica, 'monospace';
$external-button-color: #3b4b88;
$external-button-color-disabled: #9ca4c3;
$guess-color: $tr-payne-grey;
$study-header-mobile: 175px;
$study-header-desktop: 90px;
$content-on-desktop-large-max-width: 1020;
$tooltip-width: 335px;
