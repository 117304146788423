@import "variables";
@import "tr-mixins";
@import "mixins";

body {
  margin: 0;
  font-weight: 500;
  font-size: $font-size;
  color: var(--tr-color-dark, $tr-color-dark);
}

@include for-Safari() {
  html, body {
    display: unset;
  }
}
// TYPOGRAPHY
body, button {
  font-family: $font-family-public;
}

h1 {
  font-weight: 800;
  font-size: 32px;

  @include respond-to-min('medium') {
    font-size: 42px;
  }
}

h2 {
  font-weight: 800;
  @include respond-to-custom-min($tr-desktop-mobile) {
    font-size: 32px;
  }
}

h3 {
  font-weight: 700;
  font-size: 24px;
}

h4 {
  font-weight: 600;
  margin-block-start: 2 * $block-start-end;
  margin-block-end: $block-start-end;
  font-size: 20px;
}

h5 {
  font-weight: 600;
  font-size: 16px;
  margin-block-start: 10px;
  margin-block-end: 10px;
  line-height: 24px;
}

li, textarea, p {
  font-size: $font-size;
}

li {
  color: $primary-text-color;
  font-weight: 500;
}

textarea {
  font-family: $font-family-public;
}

textarea, p {
  color: $paragraph-primary-text-color;
  font-weight: $font-weight;
  line-height: $line-height;
}

p {
  margin-block-start: $block-start-end;
  margin-block-end: $block-start-end;
}

a {
  cursor: pointer;
  text-decoration: none;
}

input[type="email"], input[autocomplete="email"] {
  -webkit-touch-callout: none !important;
}
