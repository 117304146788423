@import "tr-variables";
@import "tr-mixins";

.no-display {
  display: none !important;
}

.no-wrap {
  white-space: nowrap;
}

.uppercase {
  text-transform: uppercase;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.grid {
  display: grid;
}

.inline {
  display: inline;
}

.column {
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.align-baseline {
  align-items: baseline;
}

.self-center {
  align-self: center;
}

.self-end {
  align-self: self-end;
}

.align-stretch {
  align-items: stretch;
}

.no-shrink {
  flex-shrink: 0;
}

.empty-space {
  flex: 1 1 0;
}

.center {
  text-align: center;
}

.mono {
  font-family: monospace;
}

.ellipsis {
  @include ellipsis;
}

.capitalize-first-letter::first-letter {
  text-transform: capitalize;
}

.normal-white-space {
  white-space: normal;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.gap-20 {
  gap: 20px;
}

.gap-30 {
  gap: 30px;
}

.pad-20 {
  padding: 20px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-30 {
  padding-right: 30px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-40 {
  padding-top: 40px;
}

.pr-50 {
  padding-right: 50px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-80 {
  padding-bottom: 80px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-40 {
  margin-left: 40px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-30 {
  margin-right: 30px;
}

.mbs-0 {
  margin-block-start: 0;
}

.mbs-5 {
  margin-block-start: 5px;
}

.mbs-10 {
  margin-block-start: 10px;
}

.mbs-20 {
  margin-block-start: 20px;
}

.mbs-30 {
  margin-block-start: 30px;
}

.mbs-40 {
  margin-block-start: 40px;
}

.mbs-50 {
  margin-block-start: 50px;
}

.mbs-80 {
  margin-block-start: 80px;
}

.mbs-100 {
  margin-block-start: 100px;
}

.mbe-0 {
  margin-block-end: 0;
}

.mbe-10 {
  margin-block-end: 10px;
}

.mbe-5 {
  margin-block-end: 5px;
}

.mbe-20 {
  margin-block-end: 20px;
}

.mbe-30 {
  margin-block-end: 30px;
}

.mbe-40 {
  margin-block-end: 40px;
}

.mbe-50 {
  margin-block-end: 50px;
}

.mbe-60 {
  margin-block-end: 60px;
}

.mbe-80 {
  margin-block-end: 80px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-30 {
  margin-top: 30px !important;
}

.filled {
  background-color: #f9fafd;
}

.grey-filled {
  background-color: #FAFAFA;
}

.full-width {
  width: 100%;
}
