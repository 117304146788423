@use "@angular/material" as mat;
@import "tr-variables";
@import "variables";
@import "tr-theme";
@import "tr-dialogs";
@import "tr-buttons";
@import "tr-mixins";
@import "palettes";
@import "dialog";

@include mat.core();
$primary-palette: mat.define-palette($india);
$accent-palette: mat.define-palette($jungle);
$warn-palette: mat.define-palette($fuzzy);
$public-typography: mat.define-typography-config(
  $font-family: $font-family-public,
  $headline-1: mat.define-typography-level(42px, 50px, 800),
  $headline-2: mat.define-typography-level(40px, 48px, 800),
  $headline-3: mat.define-typography-level(38px, 45.6px, 800),
  $headline-4: mat.define-typography-level(36px, 43.2px, 800),
  $headline-5: mat.define-typography-level(32px, 38px, 800),
  $headline-6: mat.define-typography-level(24px, 28px, 700),
  $subtitle-1: mat.define-typography-level(20px, 24px, 700),
  $subtitle-2: mat.define-typography-level(20px, 24px, 600),
  $body-1: mat.define-typography-level(14px, 16.8px, 500),
  $body-2: mat.define-typography-level(16px, 19.2px, 500),
  $caption: mat.define-typography-level(18px, 21.6px, 700),
  $button: mat.define-typography-level(14px, 14px, 500),
  $overline: mat.define-typography-level(14px, 1.125, 400)
);
@include mat.all-component-typographies($public-typography);

$public-app-theme: mat.define-light-theme((
  color: (
    primary: $primary-palette,
    accent: $accent-palette,
    warn: $warn-palette
  ),
  typography: $public-typography,
  density: -1
));
@include mat.all-component-themes($public-app-theme);

router-outlet {
  @include respond-to-max('mobile-1') {
    touch-action: none;
  }
}

// new UI
.mat-mdc-unelevated-button.mat-mdc-button-base[mat-flat-button] {
  min-width: 170px;
  height: $button-height;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: none;
  cursor: pointer;

  &.button-sm {
    width: 130px;
    height: 50px;
    padding: 0;
    text-align: center;

    &.button-wide {
      width: 100%;
    }
  }

  &.button-icon {
    min-width: unset;
    height: 50px;
    width: 50px;
    align-items: center;
    justify-content: center;

    .mat-icon {
      margin-right: unset;
      margin-left: unset;
    }
  }

  &:disabled {
    cursor: not-allowed;
  }

  @include mat-primary-color-button-for-survey();
  @include mat-secondary-and-tertiary-color-button();

  &.mat-loading {
    .mat-button-wrapper {
      display: none;
    }
  }

  @include mat-button-with-icon();
}

a {
  color: map-get($india, 500);
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: unset;
}

mat-card.mat-mdc-card.form-card {
  box-shadow: 0 2px 100px 0 rgba(0, 0, 0, 0.07);
  border-radius: 20px;
}

input[type=password] {
  font-family: monospace; // changes dot-mask for password on MacOS

  &::placeholder {
    font-family: $font-family-public;
  }
}

mat-form-field.mat-mdc-form-field {
  --mdc-theme-primary: #{$tr-color-primary};
  // Label part
  &.mat-form-field-appearance-outline, &.mat-form-field-has-label {
    .mat-form-field-label-wrapper {
      top: -7px;
      left: 0;

      @include respond-to-custom-max($tr-desktop-mobile - 1) {
        top: 1px;
      }

      .mat-mdc-form-field-label {
        font-size: 16px;
        color: var(--tr-color-placeholder, #{$tr-color-placeholder});
        font-weight: normal;
        text-transform: unset;

        @include respond-to-max('mobile-1') {
          font-size: 12px;
        }
      }
    }
  }

  &.mat-form-field-appearance-outline {
    &.mat-focused, &:hover {
      .mat-form-field-outline {
        color: var(--tr-color-primary, #{$tr-color-primary});
      }

      .mat-mdc-input-element {
        caret-color: var(--tr-color-primary, #{$tr-color-primary});
      }
    }

    &.ng-dirty.mat-form-field-invalid {
      .mat-form-field-outline {
        color: var(--tr-color-danger, #{$tr-color-danger});
      }

      .mat-mdc-input-element {
        caret-color: var(--tr-color-danger, #{$tr-color-danger});
      }
    }

    &.warning.mat-form-field-invalid {
      .mat-form-field-outline-thick {
        color: var(--tr-color-warning, #{$tr-color-warning});
      }

      .mat-mdc-input-element {
        caret-color: var(--tr-color-warning, #{$tr-color-warning});
      }
    }

    &.mat-form-field-has-label {
      .mat-mdc-input-element, .mat-select-value {
        padding-top: 15px;
      }
    }

    .mat-form-field-suffix {
      position: absolute;
      top: 50%;
      transform: translate(-5px, calc(-50% + 2px));
      right: 0;

      mat-icon {
        color: $tr-manatee;
        cursor: pointer;
        height: 20px;
        width: 20px;
      }
    }

    &.mat-form-field-disabled {
      .mat-form-field-suffix mat-icon,
      .mat-mdc-text-field-wrapper .mat-form-field-label-wrapper .mat-mdc-form-field-label {
        color: var(--tr-color-secondary-text-disabled, #cfcfcf);
      }
    }

    .mat-mdc-select-arrow-wrapper {
      transform: none;
    }

    .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
      color: #0009;
    }
  }
}

.mat-radio-button .mat-radio-outer-circle {
  border-color: $tr-platinum;
}

.mat-checkbox {
  .mat-checkbox-frame {
    border-color: $tr-platinum;

    svg {
      opacity: 0;
    }
  }

  &.ng-touched.ng-invalid {
    .mat-checkbox-frame {
      border-color: var(--tr-color-danger, $tr-color-danger);
    }

    & + mat-error.mat-mdc-form-field-error {
      font-size: 12px;
    }
  }
}

.mat-checkbox-checked {
  .mat-checkbox-frame {
    border-color: var(--tr-color-primary, $tr-color-primary);
  }

  &.mat-accent .mat-checkbox-background {
    background-color: $tr-color-primary;
  }

  .mat-checkbox-background {
    width: 10px;
    height: 10px;
    top: 3px;
    left: 3px;

    svg {
      opacity: 0;
    }
  }
}

mat-progress-bar.mat-accent[color='accent'] {
  height: 15px;
  border-radius: 8px;

  .mat-progress-bar-fill::after {
    background-color: var(--tr-color-primary, $tr-color-primary);
    box-shadow: 0 4px 10px 0 rgba(43, 197, 225, 0.43);
  }

  .mat-progress-bar-buffer.mat-progress-bar-element {
    background-color: #e5e7eb;
  }
}

mat-slide-toggle.mat-slide-toggle {
  .mat-slide-toggle-bar {
    height: 20px;
    width: 34px;
    border-radius: 10px;
    color: var(--tr-color-primary, $tr-color-primary);
  }

  &.mat-checked .mat-slide-toggle-bar {
    background-color: var(--tr-color-primary, $tr-color-primary);
  }

  .mat-slide-toggle-thumb {
    background-color: white;
    box-shadow: unset;
    height: 13px;
    width: 13px;
  }

  .mat-slide-toggle-thumb-container {
    top: 3px;
    left: 3px;
  }

  &.mat-primary.mat-checked .mat-slide-toggle-thumb {
    background-color: white;
    height: 13px;
    width: 13px;
  }
}

cdk-tree-node.tree-node.long-question p {
  color: var(--tr-color-primary, $tr-color-primary);
}

.mat-datepicker-content mat-calendar.mat-calendar {
  width: $tr-calendar-width;
  height: 310px;
}

td.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background-color: var(--tr-color-primary, $tr-color-primary);
}

div.mat-calendar-body-cell-content {
  width: 25px;
  height: 25px;
  border: none;
  border-radius: 0;
}

// ...........
.mat-calendar-body-cell {
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      .mat-calendar-body-cell-content {
        color: var(--tr-color-primary-text, $tr-color-primary-text);
        background-color: var(--tr-color-primary, $tr-color-primary);
      }
    }
  }
}




.mat-calendar-body-cell-content.mat-calendar-body-selected {
  background-color: var(--tr-color-primary, $tr-color-primary);

  &:hover {
    color: var(--tr-color-primary-text, $tr-color-primary-text);
  }
}
// add
@media (hover: hover) {
  @include respond-to-custom-max($tr-desktop-small - 1) {
    .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
      background-color: transparent !important;
    }
  }
}


// .............
mat-year-view, mat-multi-year-view {
  .mat-calendar-body-cell-content {
    color: var(--tr-color-dark, $tr-color-dark);
    width: 50px;

    .mat-calendar-body-cell {
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-color: var(--tr-color-primary, $tr-color-primary);
          color: var(--tr-color-primary-text, $tr-color-primary-text);
        }
    
        &.mat-calendar-body-selected {
          background-color: transparent;
          color: var(--tr-color-primary-text, $tr-color-primary-text);
        }
      }
    }
    

  }
}

.error-message {
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: normal;
  text-align: center;
  color: var(--tr-color-danger, $tr-color-danger);
}

// We use this class in RegisterFormComponent and SelectProfileTypeComponent
.tr-user-name-fields {
  @include respond-to-min('small') {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 20px;
  }
}

// KEYFRAMES
@keyframes fading {
  0% {
    opacity: .5;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: .5;
  }
}

mat-icon.study-status-icon {
  width: 68px;
  height: 68px;
  flex-shrink: 0;
}

button.study-status-button[mat-flat-button] {
  font-weight: 600;
  min-width: 170px;

  @include respond-to-custom-max($tr-desktop-small - 1) {
    width: 100%;
    margin-top: 20px;
  }

  &.mat-secondary {
    color: rgba(91, 91, 91, 1);
  }
}

@include for-Safari() {
  .cdk-overlay-container, .cdk-overlay-backdrop {
    display: unset;
  }
}

@import "survey";
