@import "tr-variables";

:root {
  --mdc-typography-button-font-weight: 600;
  --tr-color-primary: #52cae0;
  --tr-color-primary-light: #a8e4ef;
  --tr-color-primary-contrast: #3c9aab;
  --tr-color-primary-text: #ffffff;
  --tr-color-primary-text-disabled: #ffffff;

  --tr-color-secondary-light: #f2f1f6;
  --tr-color-secondary-contrast: #eae8f1;
  --tr-color-secondary-text: #{$rifle-green};
  --tr-color-secondary-text-disabled: #cfcfcf;

  --tr-color-tertiary: #5167a3;
  --tr-color-tertiary-light: #919FD3;
  --tr-color-tertiary-contrast: #263663;
  --tr-color-tertiary-text: #ffffff;
  --tr-color-tertiary-text-disabled: #9DA4C3;

  --tr-color-warning: #f5a623;

  --tr-color-danger: #ff5e5e;

  --tr-color-dark: #3f3f3f;
  --tr-color-light: #FFFFFF;
  --tr-color-placeholder: #999999;
  --tr-color-active-status: #8894c9;
  --tr-primary-gradient: linear-gradient(-180deg, rgba(119, 230, 249, 1) 0%, rgba(65, 183, 204, 1) 100%);
  --tr-tertiary-gradient: linear-gradient(-180deg, rgba(71, 89, 145, 1) 0%, rgba(34, 45, 88, 1) 100%);
  --tr-footer-gradient: linear-gradient(-90deg, rgba(40, 52, 116, 1) 0%, rgba(85, 98, 151, 1) 100%);
  --tr-tetriary-step-gradient: linear-gradient(-180deg, rgba(145, 159, 211, 1) 0%, rgba(62, 79, 141, 1) 100%);
  --tr-color-header-link: #{$tr-color-header-link};
}
